html {
    background-color: black;

    align-items: center;
    justify-content: center;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageUploadAndDisplay {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    border: black 2px;
}

.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: black;
  color: white;
}

.header {
  min-height: 50vh;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about {
  text-align: left;
  max-width: 800px;
  margin: 40px auto;
}

.footer {
  padding: 40px 0;
}

.button-link-container {
   display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
}

@media only screen and (max-width: 480px) {
  .button-link-container {
    flex-direction: column;
    width: 100%;
  }
}

.container-of-container-lol {
  display: flex;
  width: fit-content;
}