.myImage {
    width: 40%;
    height: 40%;
    border-radius: 3%;
    margin: 1rem;
    border: solid 1px white;
}

.image-upload-and-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: auto;
    padding: 10px;
    margin-top: 20px;
}

.image-upload-button {
    font: 1rem Arial, sans-serif;
    border-radius: 5px;
    background-color: #323233;
    border: 1px white solid;
    padding: 5px;
    color: white;
    margin: 6px;
}



.image-upload-and-shuffle-container {
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 10px;
}


.download-image-button {
    background-color: black;
    border: 1px white solid;
    font: 1rem Arial, sans-serif;
}

.pulsating-dot-container {
  position: relative;
  display: flex; /* or flex, depending on your layout */
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.pulsating-dot {
  width: 1rem;
  height: 1rem;
  border: solid black 1px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% { transform: translate(-50%, -50%) scale(1); }
  50% { transform: translate(-50%, -50%) scale(1.5); }
  100% { transform: translate(-50%, -50%) scale(1); }
}

@media only screen and (max-width: 480px) {
  .image-upload-and-display {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: black;
      width: auto;
      padding: 10px;
      margin-top: 100px;
}

  .myImage {
    height: 80%;
    width: 80%;
  }
}

@media only screen and (max-width: 820px) {
    .image-upload-and-display {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        width: auto;
        margin-top: 90px;
  }
}