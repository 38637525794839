footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: black;
    color: white;
    position: absolute; /* Change to absolute positioning */
    bottom: 0;
    width: 100%;
}

p {
    background: black;
    color: white;
    text-align: center;
}