.about {
  padding: 3px;
  margin-top: 5px;
  background-color: black;
  color: white;
  text-justify: left;
  margin-bottom: 5px;
  width: auto;
}

.about h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  text-decoration: underline;
}

.about p {
  line-height: 1.5;
  margin-bottom: 15px;
  text-align: left;
}

.about a {
  color: #007bff;
  text-decoration: none;
  display: inline;
  text-align: center;
  text-justify: center;
  padding: 7px;
}

.about a:hover {
  text-decoration: underline;
}

.link-container {
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  justify-content: center;
  margin: 0px;
}