.header-logo-container {
  display: flex;
  align-items: center;
  position: absolute; /* Position the component in the top left */
  top: 10px;
  left: 10px;
}

.header-logo-image {
  width: 5rem; /* Adjust the width and height as needed */
  height: 5rem;
  margin-right: 10px; /* Space between the image and the text */
  border-radius: 50%;
}

.header-logo-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: white; /* Adjust the color as needed */
}
