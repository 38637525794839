.container {
    justify-content: center;
    width: 5px;
}


@media only screen and (max-width: 480px) {
    .container {
        width:fit-content
    }
}

.substack-logo {
    width: 15%;
    height: 15%;
    margin: .25rem;
}

a {
    text-decoration: none;
    color: white;
    width: fit-content;
}


.visit-my-substack-container {
    display: flex;
    flex-direction: horizontal;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 5px;
    color: black;
    padding: 15px;
    text-align: center;
    width: min-content;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 20px;
}

h3 {
    color: black; 
    width: fit-content;
    white-space: nowrap;
}

.link-container {
  display: flex;
  justify-content: center;
  gap: 5px;
  width: min-content;
}

.visit-my-substack-container:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

